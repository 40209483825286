<template>
  <v-app>
    <div class="row">
      <div class="col-md-4">
        <h3>
          Websites
        </h3>
      </div>
      <div class="col-md-8 text-right">

        <router-link :to="{name:'website-trash-dashboard'}" class=""><i class="fa fa-trash"></i> Deleted Websites
        </router-link>
        <v-btn-toggle @change="updateDashboardViewPref()" rounded dense v-model="grid">
          <v-btn>
            <v-icon>mdi-grid</v-icon>
          </v-btn>

          <v-btn>
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>

        </v-btn-toggle>
      </div>
    </div>
    <div class="row">
      <KTPortlet v-if="!loading && grid == 1 ">
        <template v-slot:body>
          <div class="row" v-if="loading">
            <div class="col-md-12">
              <v-skeleton-loader
                  class="mx-auto"
                  type="table"
              ></v-skeleton-loader>
            </div>
          </div>
          <div class="row" v-else>

            <b-table
                responsive
                hover
                :items="websites"
                id="my-table"
                :busy.sync="loading"
                :fields="fields"
            >
              <template v-slot:table-busy>
                <div class="text-center text-info my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading Products...</strong>
                </div>
              </template>

              <template v-slot:cell(image)="data">
                <progressive-img
                    :src="data.item.template_image"
                    height="40"
                    width="60"
                    class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </progressive-img>
              </template>
              <!--        <template v-slot:cell(id)="data">-->
              <!--          <v-checkbox-->
              <!--              v-model="selected"-->
              <!--              class="mx-2"-->
              <!--              :value="data.item.id"-->
              <!--          ></v-checkbox>-->
              <!--        </template>-->
              <template v-slot:cell(title)="data">
                <router-link
                    :to="{ name: 'manage-websites', params: { domainname: (data.item.domain_name && data.item.is_custom_domain)?data.item.domain_name:data.item.sub_domain_name }}"
                    class="kt-nav__link"
                >
                        <span class="kt-nav__link-text">{{
                            data.item.title
                          }}</span>
                  <br/>
                </router-link>

                <span class="text-success" v-if="data.item.is_active">
                        Active
                        <br/>
                      </span>
                <span class="text-danger" v-else>
                        Inactive
                        <br/>
                      </span>
              </template>
              <template v-slot:cell(plan_expiry_date)="data">
          <span class="btn btn-label-info btn-sm btn-bold btn-upper">{{
              data.item.plan_expiry_date ? formatDate(data.item.plan_expiry_date) : 'No Plan Available'
            }}</span>
              </template>
              <template v-slot:cell(plan_id)="data">

                <div class="kt-widget__label">
            <span class="btn btn-label-brand btn-sm btn-bold btn-upper kt-margin-r-10"
                  v-if="!data.item.plan_id">Free Plan</span>
                  <span class="btn btn-label-brand btn-sm btn-bold btn-upper kt-margin-r-10"
                        v-else>{{ data.item.subscription.title | '' }}</span>
                </div>
              </template>
              <template v-slot:cell(domain)="data">

                <div class="kt-widget__label" v-if="data.item.is_custom_domain">

                  <span class="btn btn-label-warning btn-sm btn-bold btn-upper kt-margin-r-10">Custom domain</span>
                </div>
                <div class="kt-widget__label" v-if="data.item.has_ssl && data.item.is_custom_domain">
                  <span class="btn btn-label-success btn-sm btn-bold btn-upper kt-margin-r-10">SSL secured</span>
                </div>
                <div class="kt-widget__label" v-if="data.item.setup_steps<5">
                  <span
                      class="btn btn-label-danger btn-sm btn-bold btn-upper kt-margin-r-10">Pending setup</span>
                </div>
                <div class="kt-widget__label" v-if="data.item.setup_steps<5">
                  <a class="btn btn-label-success btn-sm btn-bold btn-upper kt-margin-r-10"
                     @click.prevent="checkSteps(data.item.setup_steps,(data.item.domain_name && data.item.is_custom_domain)?item.domain_name:item.sub_domain_name)">Resume
                    Setup</a>
                </div>
              </template>
              <!-- A virtual composite column -->
              <template v-slot:cell(action)="data">
                <div class="kt-widget__toolbar">
                  <a href="#" class="btn btn-clean btn-sm btn-icon btn-icon-md"
                     data-toggle="dropdown">
                    <i class="flaticon-more-1"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                    <ul class="kt-nav">
                      <li class="kt-nav__item">
                        <router-link class="kt-nav__link"
                                     :to="{ name: 'manage-websites',  params: { domainname: data.item.domain_name ? data.item.domain_name:  data.item.sub_domain_name }}">
                          <i class="kt-nav__link-icon flaticon2-settings"></i>
                          <span class="kt-nav__link-text">Manage</span>
                        </router-link>

                      </li>
                      <li class="kt-nav__item">
                        <a v-bind:href="'http://'+domainUrl(data.item)" target="_blank"
                           class="kt-nav__link">
                          <i class="kt-nav__link-icon flaticon-globe"></i>
                          <span class="kt-nav__link-text"> Visit website</span>
                        </a>
                      </li>
                      <li class="kt-nav__item" v-if="data.item.is_custom_domain">
                        <a href="#" target="_blank" class="kt-nav__link"
                           @click.prevent="disconnectWebsite(data.item)">
                          <i class="kt-nav__link-icon flaticon-attachment"></i>
                          <span class="kt-nav__link-text"> Disconnect domain</span>
                        </a>
                      </li>
                      <li class="kt-nav__item">
                        <a href="#" target="_blank" class="kt-nav__link"
                           @click.prevent="deleteWebsite(data.item)">
                          <i class="kt-nav__link-icon flaticon-delete"></i>
                          <span class="kt-nav__link-text"> Delete</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <!-- <pre>{{selected}}</pre> -->
        </template>
      </KTPortlet>

      <div v-if="!loading && grid == 0" class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
           v-for="(item,index) in websites "
           :key="index">
        <!--begin:: Portlet-->
        <div class="kt-portlet kt-portlet--height-fluid">
          <div class="kt-portlet__body kt-portlet__body--fit">
            <!--begin::Widget -->
            <div class="kt-widget kt-widget--project-1">
              <div class="kt-widget__head d-flex p-2">
                <div style="flex-basis: 80%; max-width: 80%; word-break: break-all;" class="kt-widget__label">
                  <div class="kt-widget__media kt-widget__media--m">
                  </div>
                  <div class="kt-widget__info kt-padding-0 ">
                    <router-link class="kt-widget__title p-4"
                                 :to="{ name: 'manage-websites', params: { domainname: (item.domain_name && item.is_custom_domain)?item.domain_name:item.sub_domain_name }}">
                      {{
                        (item.domain_name &&
                            item.is_custom_domain) ? item.domain_name : item.sub_domain_name
                      }}
                    </router-link>

                    <span class="kt-widget__desc">{{ item.plan }}</span>
                  </div>
                </div>
                <div class="kt-widget__toolbar">
                  <a href="#" class="btn btn-clean btn-sm btn-icon btn-icon-md"
                     data-toggle="dropdown">
                    <i class="flaticon-more-1"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                    <ul class="kt-nav">
                      <li class="kt-nav__item">
                        <router-link class="kt-nav__link"
                                     :to="{ name: 'manage-websites',  params: { domainname: item.domain_name ? item.domain_name:  item.sub_domain_name }}">
                          <i class="kt-nav__link-icon flaticon2-settings"></i>
                          <span class="kt-nav__link-text">Manage</span>
                        </router-link>

                      </li>
                      <li class="kt-nav__item">
                        <a v-bind:href="'http://'+domainUrl(item)" target="_blank"
                           class="kt-nav__link">
                          <i class="kt-nav__link-icon flaticon-globe"></i>
                          <span class="kt-nav__link-text"> Visit website</span>
                        </a>
                      </li>
                      <li class="kt-nav__item" v-if="item.is_custom_domain">
                        <a href="#" target="_blank" class="kt-nav__link"
                           @click.prevent="disconnectWebsite(item)">
                          <i class="kt-nav__link-icon flaticon-attachment"></i>
                          <span class="kt-nav__link-text"> Disconnect domain</span>
                        </a>
                      </li>
                      <li class="kt-nav__item">
                        <a href="#" target="_blank" class="kt-nav__link"
                           @click.prevent="deleteWebsite(item)">
                          <i class="kt-nav__link-icon flaticon-delete"></i>
                          <span class="kt-nav__link-text"> Delete</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <router-link class="kt-widget__title"
                           :to="{ name: 'manage-websites', params: { domainname: (item.domain_name && item.is_custom_domain)?item.domain_name:item.sub_domain_name }}">
                <img :src="item.template_image" class="img-fluid"
                     alt="">
              </router-link>
              <div class="kt-widget__body">
                <!--                <div class="kt-widget__stats">-->
                <!--                  <div class="kt-widget__item d-flex align-items-center">-->
                <!--                    <div class="kt-widget__label">-->
                <!--                      &lt;!&ndash;                      <pre>{{item}}</pre>&ndash;&gt;-->
                <!--                      <span class="btn btn-label-brand btn-sm btn-bold btn-upper kt-margin-r-10" v-if="!item.plan_id">Free Plan</span>-->
                <!--                      <span class="btn btn-label-brand btn-sm btn-bold btn-upper kt-margin-r-10" v-else>{{item.subscription.title | ''}}</span>-->
                <!--                    </div>-->
                <!--                    <div class="kt-widget__label" v-if="item.is_custom_domain">-->

                <!--                      <span class="btn btn-label-warning btn-sm btn-bold btn-upper kt-margin-r-10">Custom Domain</span>-->
                <!--                    </div>-->
                <!--                    <div class="kt-widget__label" v-if="item.has_ssl && item.is_custom_domain">-->
                <!--                      <span class="btn btn-label-success btn-sm btn-bold btn-upper kt-margin-r-10">SSL Secured</span>-->
                <!--                    </div>-->
                <!--                    <div class="kt-widget__label" v-if="item.setup_steps<5">-->
                <!--                      <span class="btn btn-label-danger btn-sm btn-bold btn-upper kt-margin-r-10" >Incomplete Website Setup</span>-->
                <!--                    </div>-->
                <!--                    <div class="kt-widget__label" v-if="item.setup_steps<5">-->
                <!--                      <a class="btn btn-label-success btn-sm btn-bold btn-upper kt-margin-r-10" @click.prevent="checkSteps(item.setup_steps,(item.domain_name && item.is_custom_domain)?item.domain_name:item.sub_domain_name)">Resume Setup</a>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->

                <!--                <div class="row mt-2">-->
                <!--                  <div class="col-md-2">-->
                <!--                    <span class="kt-widget__subtitel">Storage</span>-->
                <!--                  </div>-->
                <!--                  <div class="col-md-7">-->
                <!--                    <b-progress v-if="item.volume_used" :variant="'success'"-->
                <!--                                :value="item.volume_used.percentage_used"-->
                <!--                                show-progress class="mb-3"></b-progress>-->
                <!--                    <b-progress v-else :value="value" :variant="'success'" show-progress-->
                <!--                                class="mb-3"></b-progress>-->
                <!--                  </div>-->
                <!--                  <div class="col-md-3">-->
                <!--                    <strong v-if="item.volume_used">{{-->
                <!--                      item.volume_used.percentage_used.toFixed(2)-->
                <!--                      }} %</strong>-->
                <!--                    <strong v-else>0%</strong>-->
                <!--                  </div>-->
                <!--                </div>-->

                <!--                <div class="kt-widget__stats kt-margin-t-5">-->
                <!--                  <div class="kt-widget__item d-flex align-items-center kt-padding-l-0">-->
                <!--                    <span class="kt-widget__date kt-padding-0 kt-margin-r-10"> Expiry</span>-->
                <!--                    <div class="kt-widget__label">-->
                <!--                      <span-->
                <!--                              class="btn btn-label-info btn-sm btn-bold btn-upper">{{ item.plan_expiry_date ? formatDate(item.plan_expiry_date) : 'No Plan Available' }}</span>-->
                <!--                    </div>-->
                <!--                  </div >-->

                <!--                </div>-->
              </div>
              <!--              <div class="kt-widget__footer">-->
              <!--                <div class="kt-widget__wrapper">-->
              <!--                  <div class="kt-widget__section">-->
              <!--                    <div class="kt-widget__blog">-->
              <!--                      <i class="flaticon2-list-1"></i>-->
              <!--                      <a href="#" class="kt-widget__value kt-font-brand">{{-->
              <!--                        item.total_page ?-->
              <!--                        item.total_page : 0-->
              <!--                        }} Pages</a>-->
              <!--                    </div>-->

              <!--                    <div class="kt-widget__blog pl-2">-->
              <!--                      <i class="flaticon2-talk"></i>-->
              <!--                      <a href="#" class="kt-widget__value kt-font-brand">{{-->
              <!--                        item.total_blog ?-->
              <!--                        item.total_blog : 0-->
              <!--                        }} Blogs</a>-->
              <!--                    </div>-->
              <!--                    &lt;!&ndash; <div class="kt-widget__blog pl-2">-->
              <!--                          <i class="flaticon2-list-1"></i>-->
              <!--                          <a href="#" class="kt-widget__value kt-font-brand">{{ item.total_product ? item.total_product : 0 }} Products</a>-->
              <!--                        </div> &ndash;&gt;-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>

            <!--end::Widget -->
          </div>
        </div>

        <!--end:: Portlet-->
      </div>
      <div v-if="loading" class="col-xl-4 col-lg-4 col-md-6 col-sm-12" v-for="(item,index) in 4 " :key="index">
        <div class="container">
          <v-skeleton-loader v-if="loading"
                             type="list-item-avatar, article,list-item, actions"></v-skeleton-loader>
        </div>
      </div>
      <div class="col-xl-4 col-md-4 col-sm-12">
        <div class="kt-portlet kt-iconbox kt-iconbox--brand kt-iconbox--animate-slower">
          <div class="kt-portlet__body">
            <div class="kt-iconbox__body">
              <div class="kt-iconbox__icon">
                <i class="flaticon2-ui kt-font-success kt-font-big" style="font-size:60px"></i>
              </div>
              <div class="kt-iconbox__desc">
                <h3 class="kt-iconbox__title">
                  <a :href="createWebsiteUrl" class="kt-link">Create website
                  </a>
                </h3>
                <div class="kt-iconbox__content">
                  Got a new business ? Make a website today
                  <hr/>
                  <div class="v-card__actions pull-right">
                    <button type="button"
                            class="btn btn btn-primary btn-elevate kt-login__btn-primary kt-spinner--light kt-spinner--right btn-secondary">

                      <a :href="createWebsiteUrl" class="v-btn__content" target="_blank">
                        View templates
                      </a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-col sm="12" md="12" lg="12" v-if="total>1">

        <div class="text-right">
          <v-pagination
              v-model="currentPage"
              :length="total"
              prev-icon="mdi-menu-left"
              next-icon="mdi-menu-right"
              @input="getWebsites()"
          ></v-pagination>
        </div>
      </v-col>
      <!-- <div class="col-xl-4 col-md-4 col-sm-12">
            <div class="kt-portlet kt-iconbox kt-iconbox--brand kt-iconbox--animate-slower">
              <div class="kt-portlet__body">
                <div class="kt-iconbox__body">
                  <div class="kt-iconbox__icon">
                    <i class="fa fa-user-tie kt-font-success kt-font-big" style="font-size:60px"></i>
                  </div>
                  <div class="kt-iconbox__desc">
                    <h3 class="kt-iconbox__title">
                      <a class="kt-link" href="#">Hire an Expert</a>
                    </h3>
                    <div class="kt-iconbox__content">
                      <br />Looking for help ? Contact us today
                      <hr />
                      <div class="v-card__actions pull-right">
                        <button
                          type="button"
                          class="btn btn btn-primary btn-elevate kt-login__btn-primary kt-spinner--light kt-spinner--right btn-secondary"
                        >
                          <span class="v-btn__content">Contact us</span>
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
    </div>
    <v-dialog v-model="confirmDeleteDialog" max-width="600">
      <v-card>
        <v-card-title>
          Confirm Delete Website
        </v-card-title>
        <v-card-text>
          <v-text-field v-model.trim="websiteToDelete" label="Type Domain/Subdomain Name" outlined dense>

          </v-text-field>
          <span class="text-danger" v-if="errorMessage"> {{ errorMessage }}</span>
        </v-card-text>
        <v-card-actions>
          <v-row class="p-5">
            <v-col class="text-right">
              <v-btn @click="confirmDeleteWebsite" class="btn btn-lg btn-add-main mr-2">
                Confirm Delete
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import WebsiteCustomerService from "@/services/store/EcommerceCustomerService";
import {mapState} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ContextMenu1 from "@/views/partials/widgets/ContextMenu1.vue";
import Widget4 from "@/views/partials/widgets/Widget4.vue";
import Widget5 from "@/views/partials/widgets/Widget5.vue";
import OrganisationWidget from "@/views/partials/widgets/OrganisationWidget.vue";

import Widget14_1 from "@/views/partials/widgets/Widget14_MiniDailySales.vue";
import Widget14_2 from "@/views/partials/widgets/Widget14_MiniProfitShare.vue";
import Widget14_3 from "@/views/partials/widgets/Widget14_MiniRevenueChange.vue";
import Widget17 from "@/views/partials/widgets/Widget17.vue";
import Widget20_1 from "@/views/partials/widgets/Widget20_Inbound.vue";
import Widget20_2 from "@/views/partials/widgets/Widget20_Outbound.vue";
import Timeline2 from "@/views/partials/widgets/Timeline2.vue";
import Website from "@/services/Websites/WebsiteService";
import DomainService from "@/services/domain/DomainService";
import ProductService from "@/services/store/ProductService";
import OgranisationService from "@/services/organisation/OrganisationService";
import CartService from "@/services/cart/CartService";
/**

 /**
 * Sample widgets data source
 */
import widget4 from "@/common/mock/widget-4.json";
import widget5 from "@/common/mock/widget-5.json";
import RegisterDomain from "@/views/domain/RegisterDomain";
import moment from "moment";
import DomainVerificationService from "@/services/domain/DomainVerificationService";

const WebsiteCustomer = new WebsiteCustomerService();

const WebsiteService = new Website();
const domainService = new DomainService();
const Product = new ProductService();
const cartService = new CartService()
const verificationService = new DomainVerificationService();
import ApiService from "@/common/api.service";

export default {
  name: "dashboard",
  components: {
    KTPortlet,
    ContextMenu1,
    Widget4,
    Widget5,
    OrganisationWidget,
    RegisterDomain,
    Widget14_1,
    Widget14_2,
    Widget14_3,
    Widget17,
    Widget20_1,
    Widget20_2,
    Timeline2,
  },
  data() {
    return {
      // timelines: timelines,
      loading: true,
      widget4: widget4,
      widget5: widget5,
      tabIndex: 0,
      tabIndex2: 0,
      domain_name: null,
      errorMessage: null,
      userId: null,
      websiteToDelete: null,
      search: null,
      validDomain: true,
      custom_domains: [],
      websites: [],
      overlay: false,
      confirmDeleteDialog: false,
      itemToDelete: null,
      domains: [],
      managed_dns: [],
      organisations: [],
      customers: [],
      meta: null,
      createWebsiteUrl: "",
      registerDomainUrl: "",
      productCount: 0,
      total: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      pending_invoice: [],
      grid: 0,
      fields: [
        // {key: "id", label: "", sortable: false},
        {key: "image", label: "Image", sortable: false},
        {key: "title", label: "Title", sortable: true},
        {key: "plan_expiry_date", label: "Expiry", sortable: true},
        {key: "plan_id", label: "Plan", sortable: true},
        {key: "domain", label: "Domain", sortable: true},
        {key: "belongs_to", label: "Owner", sortable: true},
        {key: "action", label: "Action", sortable: false},
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Dashboard"
    }]);
    this.createWebsiteUrl = process.env.VUE_APP_SERVER_URL + 'templates';
    this.registerDomainUrl = process.env.VUE_APP_SERVER_URL + "services/domain";
    // this.getOrganisations();
    this.userId = this.$store.getters.currentUser.id;
    this.getWebsites();
    this.getCustomers();
    this.countProducts();
    this.getPendingInvoice();
    this.getNonVerifiedCustomDomain()
    this.grid = this.currentUser.website_view_pref_grid ? true : false;
  },
  methods: {

    getWebsites() {
      WebsiteService.paginate(this.currentPage).then((res) => {
        this.websites = res.data.data;
        this.loading = false;
        this.perPage = res.data.meta.per_page;
        this.total = res.data.meta.last_page;
      });
    },
    updateDashboardViewPref() {
      {
        let user_date = this.currentUser;
        user_date.website_view_pref_grid = this.grid;
        // this.isLoading = true;
        ApiService.post("user/update", user_date).then(response => {
          // this.$snotify.success("Website View Preference saved!");
        }).catch(error => {
          // console.log(error)
        }).finally(() => {
          // this.isLoading = false;
        });
      }
    },

    getNonVerifiedCustomDomain() {
      verificationService.getNonVerifiedDomains().then(response => {
        this.custom_domains = response.data
      }).catch(error => {
        this.$snotify.error('Something went wrong')
      })
    },
    getCustomers() {
      WebsiteCustomer.paginate(this.userId, this.search).then(res => {
        this.customers = res.data.data;
        this.meta = res.data.meta;
      });
    },
    getOrganisations() {
      ogranisationService
          .paginate()
          .then((res) => {
            this.organisations = res.data.data;
          })
          .catch((err) => {
          });
    },
    manageSubscription() {
      window.location.href =
          process.env.VUE_APP_SERVER_URL + "my-websites";
    },
    domainUrl(item) {
      return item.domain_name ? item.domain_name : item.sub_domain_name;
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },

    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },

    getAllDomain() {
      domainService
          .paginate()
          .then((response) => {
            this.domains = response.data.data;
          })
          .catch((error) => {
          });
    },

    deleteWebsite(item) {
      this.itemToDelete = null;
      this.websiteToDelete = null;
      this.errorMessage = null;
      this.itemToDelete = item;
      this.confirmDeleteDialog = true

    },
    confirmDeleteWebsite() {
      if (this.websiteToDelete == this.itemToDelete.domain_name || this.websiteToDelete == this.itemToDelete.sub_domain_name) {
        let domain = this.itemToDelete.domain_name ? this.itemToDelete.domain_name : this.itemToDelete.sub_domain_name;
        WebsiteService.delete(domain).then((res) => {
          this.$snotify.success("Website deleted");
          this.errorMessage = null;
          this.confirmDeleteDialog = false;
          this.getWebsites();
        });
      } else {
        this.errorMessage = 'Website DomainName/SubDomain does not match';
      }
    },

    toggleBusy() {
      this.isBusy = !this.isBusy;
    },

    /* Product */
    countProducts() {
      Product.getCounts(this.currentUser.id).then((res) => {
        this.productCount = res.data.all;
      });
    },

    getPendingInvoice() {
      cartService.getPendingInvoice().then(response => {
        this.pending_invoice = response.data

      })
    },
    checkSteps(step, domain) {
      let url = null;
      let serverUrl = process.env.VUE_APP_SERVER_URL;
      switch (step) {
        case 1:
          url = serverUrl + "website/" + domain + "/details";
          break;
        case 2:
          url = serverUrl + "website/" + domain + "/available-pages";
          break;
        case 3:
          url = serverUrl + "website/" + domain + "/blog";
          break;
        case 4:
          url = serverUrl + "website/" + domain + "/currency";
          break;
        case 5:
          url = null;
          break;
        case 6:
          url = null;
        case 7:
          url = null;
          break;
        default:
          url = serverUrl + "website/" + domain + "/type";
          break;
      }
      if (url && url !== undefined && url !== null) {
        window.location.href = url;
      }
    },


    deleteCustomDomainVerification(domain_name) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            verificationService.delete(domain_name).then(response => {
              this.$snotify.success('Request processed successfully')
              this.getNonVerifiedCustomDomain();
            }).catch(error => {
              this.$snotify.error('Something went wrong')
            })
          }
        }
      });

    }

  },
  created: function () {
    if (this.$store.getters.currentUser.is_first_social_login)
      this.$router.push({
        name: "social-account-setup"
      });

    this.getAllDomain();

  },
  computed: {
    ...mapState({
      cart: (state) => state.cart.cart,
    }),
    verifiedUserPercentage() {
      if (this.meta != null) {
        return ((this.meta.total_verified_customers / this.meta.total) * 100).toFixed(2)
      }
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
};
</script>
