<template>
  <div class="kt-widget5">
    <perfect-scrollbar style="max-height: 100vh; position: relative;">
      <template v-for="(item, i) in datasrc">
        <div class="kt-widget5__item" :key="`item-${i}`">
          <div class="kt-widget5__content">
            <div class="kt-widget5__pic">
              <img class="kt-widget7__img" :src="item.attribute.logo_image_path" alt />
            </div>
            <div class="kt-widget5__section">
              <a href="#" class="kt-widget5__title">{{ item.title }}</a>
              <span v-for="location in item.addresses" :key="location.id">
                <p class="text-left" v-if="location.is_primary">
                  <i class="flaticon2-placeholder"></i>
                  {{ (location.address_line_1)?location.address_line_1+', ':'' }}
                  {{ (location.area)?location.area+",":'' }}
                  {{ (location.city)?location.city+',':'' }}
                  {{ (location.state)?location.state+"":'' }}
                </p>
              </span>
              <p class="text-left">
                <i class="flaticon2-new-email"></i>
                {{ item.email }}
              </p>
            </div>
          </div>
          <div class="kt-widget5__content">
            <div class="kt-widget5__stats">
              <span class="kt-widget5__number" > {{ item.views ? item.views : 'N/A'}}</span>
              <span class="kt-widget5__sales">Views</span>
            </div>

            <div class="kt-widget5__stats">
              <span class="kt-widget5__number" v-text="item.total_blog"></span>
              <span class="kt-widget5__votes">Reviews</span>
            </div>

            <!-- <div class="kt-widget5__stats">
              <span class="kt-widget5__number">12</span>
              <span class="kt-widget5__votes">products</span>
            </div>-->
          </div>
        </div>
      </template>
    </perfect-scrollbar>
  </div>
</template>

<script>
export default {
  name: "OrganisationWidget",
  components: {},
  props: {
    datasrc: Array
  }
};
</script>
