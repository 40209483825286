<template>
    <div class="kt-widget17">
        <div
            class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides"
            style="background-color: #fd397a"
        >
            <div class="kt-widget17__chart" style="height:320px;">
                <Chart1 ref="chart" v-bind:options="chartOptions"></Chart1>
            </div>
        </div>
        <div class="kt-widget17__stats">
            <div class="kt-widget17__items">
                <div class="kt-widget17__item">
                    <span class="kt-widget17__icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--brand'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M5 3h1a1 1 0 011 1v16a1 1 0 01-1 1H5a1 1 0 01-1-1V4a1 1 0 011-1zm5 0h1a1 1 0 011 1v16a1 1 0 01-1 1h-1a1 1 0 01-1-1V4a1 1 0 011-1z" fill="#000"/><rect fill="#000" opacity=".3" transform="rotate(-19 17.826 11.946)" x="16.326" y="2.946" width="3" height="18" rx="1"/></g></svg>
                    </span>
                    <span class="kt-widget17__subtitle">
                        Delivered
                    </span>
                    <span class="kt-widget17__desc">
                        15 New Paskages
                    </span>
                </div>

                <div class="kt-widget17__item">
                    <span class="kt-widget17__icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.934 16.072l6.426-5.116.158-.125a1.5 1.5 0 00.003-2.35l-6.587-5.238a1.5 1.5 0 00-1.868 0L4.475 8.485a1.5 1.5 0 00-.001 2.347l.156.124 6.436 5.117a1.5 1.5 0 001.868 0z" fill="#000" fill-rule="nonzero"/><path d="M11.056 18.67l-5.72-4.547a.903.903 0 00-1.276.154.91.91 0 00.166 1.279l6.861 5.247a1.5 1.5 0 001.823 0l6.858-5.243a.915.915 0 00.167-1.288.91.91 0 00-1.286-.155l-5.725 4.554a1.5 1.5 0 01-1.868 0z" fill="#000" opacity=".3"/></g></svg></span>
                    <span class="kt-widget17__subtitle">
                        Ordered
                    </span>
                    <span class="kt-widget17__desc">
                        72 New Items
                    </span>
                </div>
            </div>
            <div class="kt-widget17__items">
                <div class="kt-widget17__item">
                    <span class="kt-widget17__icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--warning'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.704 14l2.963-4h-2.223V6L9 12h2.222v2H6a1 1 0 01-1-1V3a1 1 0 011-1h12a1 1 0 011 1v10a1 1 0 01-1 1h-5.296z" fill="#000" opacity=".3"/><path d="M9.804 10.914L9 12h2.222v4l4.445-6h-.205l4.745-3.427a.5.5 0 01.793.405V17a2 2 0 01-2 2H5a2 2 0 01-2-2V6.978a.5.5 0 01.793-.405l6.011 4.341z" fill="#000"/></g></svg></span>
                    <span class="kt-widget17__subtitle">
                        Reported
                    </span>
                    <span class="kt-widget17__desc">
                        72 Support Cases
                    </span>
                </div>

                <div class="kt-widget17__item">
                    <span class="kt-widget17__icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--danger'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M3 16h2a1 1 0 000-2H3v-2h2a1 1 0 000-2H3V8h2a1 1 0 100-2H3V4a1 1 0 011-1h6a1 1 0 011 1v15a1 1 0 01-1 1H4a1 1 0 01-1-1v-3z" fill="#000" opacity=".3"/><path d="M16 3h3a2 2 0 012 2v10.249a2 2 0 01-.495 1.317l-2.629 3.004a.5.5 0 01-.752 0l-2.63-3.004A2 2 0 0114 15.249V5a2 2 0 012-2z" fill="#000"/></g></svg></span>
                    <span class="kt-widget17__subtitle">
                        Arrived
                    </span>
                    <span class="kt-widget17__desc">
                        34 Upgraded Boxes
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Chart from "chart.js";
import Chart1 from "@/views/partials/widgets/Chart1.vue";

export default {
    name: "widget-17",
    components: {
        Chart1
    },
    data() {
        return {
            chartOptions: {}
        };
    },
    mounted() {
        this.chartOptions = {
            data: {
                labels: [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October"
                ],
                datasets: [
                    {
                        label: "Sales Stats",
                        backgroundColor: Chart.helpers
                            .color("#e14c86")
                            .alpha(1)
                            .rgbString(),
                        borderColor: "#e13a58",
                        pointBackgroundColor: Chart.helpers
                            .color("#000000")
                            .alpha(0)
                            .rgbString(),
                        pointBorderColor: Chart.helpers
                            .color("#000000")
                            .alpha(0)
                            .rgbString(),
                        pointHoverBackgroundColor: this.layoutConfig(
                            "colors.state.light"
                        ),
                        pointHoverBorderColor: Chart.helpers
                            .color("#ffffff")
                            .alpha(0.1)
                            .rgbString(),
                        data: [10, 14, 12, 16, 9, 11, 13, 9, 13, 15]
                    }
                ]
            }
        };
    },
    computed: {
        ...mapGetters(["layoutConfig"])
    }
};
</script>
