<template>
    <ul class="kt-nav">
        <li class="kt-nav__section kt-nav__section--first">
            <span class="kt-nav__section-text">Finance</span>
        </li>
        <li class="kt-nav__item">
            <a href="#" class="kt-nav__link">
                <i class="kt-nav__link-icon flaticon2-graph-1"></i>
                <span class="kt-nav__link-text">Statistics</span>
            </a>
        </li>
        <li class="kt-nav__item">
            <a href="#" class="kt-nav__link">
                <i class="kt-nav__link-icon flaticon2-calendar-4"></i>
                <span class="kt-nav__link-text">Events</span>
            </a>
        </li>
        <li class="kt-nav__item">
            <a href="#" class="kt-nav__link">
                <i class="kt-nav__link-icon flaticon2-layers-1"></i>
                <span class="kt-nav__link-text">Reports</span>
            </a>
        </li>
        <li class="kt-nav__section">
            <span class="kt-nav__section-text">Customers</span>
        </li>
        <li class="kt-nav__item">
            <a href="#" class="kt-nav__link">
                <i class="kt-nav__link-icon flaticon2-calendar-4"></i>
                <span class="kt-nav__link-text">Notifications</span>
            </a>
        </li>
        <li class="kt-nav__item">
            <a href="#" class="kt-nav__link">
                <i class="kt-nav__link-icon flaticon2-file-1"></i>
                <span class="kt-nav__link-text">Files</span>
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    name: "context-menu-1",
    components: {},
    mounted() {}
};
</script>
