<template>
  <div class="row" v-if="response.status">
    <div class="col-xl-12">
      <div class="kt-portlet">
        <div class="row text-center" v-if="response.status=='yes'">
          <div class="col-lg-12 col-sm-12 col-md-12 m-3">
            <h3 class="cl-success">Great ! your domain is available!</h3>
            <p>Your domain name is available, get it while you can.</p>
            <h3
              :class="response.status=='yes'?'text-success':'text-danger'"
            >{{response.domain_name}}</h3>
          </div>
          <div class="col-lg-4 col-sm-4 col-md-4 text-center"></div>
          <div class="col-lg-4 col-sm-4 col-md-4 text-center">
            <b-form-select v-model="term" :options="terms" value-field="value" text-field="text"></b-form-select>
          </div>
          <div class="col-lg-4 col-sm-4 col-md-4 text-center"></div>
          <div class="col-lg-12 col-sm-12 col-md-12 text-center" v-if="!existIncart">
            <h5 class="text-success">{{response.currency}} {{response.price}}</h5>
            <b-button
              @click.prevent="buildCartObject"
              type="submit"
              id="kt_submit"
              lg="4"
              variant="success"
            >Add To Cart</b-button>
          </div>
          <div class="col-lg-12 col-sm-12 col-md-12 text-center" v-else>
            <span class="text text-danger">
              Domain is already added to cart
              <router-link :to="{name:'cart'}">Continue To Cart</router-link>
            </span>
          </div>
        </div>
        <div class="row text-center" v-else-if="response.status=='no'">
          <div class="col-lg-12 col-sm-12 col-md-12 m-3">
            <h3 class="cl-success">Sorry domain not available!</h3>
            <p>Try a different domain ?</p>
            <h4
              :class="response.status=='yes'?'text-success':'text-danger'"
            >{{response.domain_name}}</h4>
          </div>
        </div>
        <div class="row text-center" v-else>
          <div class="col-lg-12 col-sm-12 col-md-12 m-3">
            <h3 class="cl-success">Something went wrong!</h3>
            <p>{{response.domain_name}}(Invalid TLD govt)</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["response", "existIncart", "overlay"],
  data() {
    return {
      domain_price: 0,
      domain_name: null,
      validDomain: true,
      term: 1,
      terms: [
        { text: "1 year", value: "1" },
        { text: "2 year", value: "2" },
        { text: "3 year", value: "3" },
        { text: "4 year", value: "4" },
        { text: "5 year", value: "5" },
        { text: "6 year", value: "6" },
        { text: "7 year", value: "7" },
        { text: "8 year", value: "8" },
        { text: "9 year", value: "9" },
        { text: "9 year", value: "9" },
        { text: "10 year", value: "10" }
      ]
    };
  },
  watch: {
    term(val) {
      this.response.price = parseFloat(this.domain_price) * parseInt(val);
    }
  },
  updated() {
    if (this.domain_price == 0) this.domain_price = this.response.price;
  },
  methods: {
    ...mapActions(["ADD_TO_CART"]),
    buildCartObject(item) {
      this.cart = {
        item_type: "domain",
        domain_name: this.response.domain_name,
        purchase_type: "new",
        terms: parseInt(this.term) * 12,
        item_price: this.response.price,
        is_processed: 0
      };
      this.addToCart();
    },
    addToCart() {
      if (this.cart) {
        this.ADD_TO_CART(this.cart)
          .then(response => {
            setTimeout(() => {
              this.$snotify.success("Added to cart");
              this.$emit("check-cart");
            }, 3000);
          })
          .catch(error => {
            this.$snotify.error("Error occured");
          });
      }
    }
  }
};
</script>