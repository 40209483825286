<template>
  <div class>
    <div class="kt-portlet">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">Start with a new domain</h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <b-form-input
          v-model="domain_name"
          :style="validDomain||{border:'1px solid red'}"
          id="example-input-0"
          aria-describedby="input-0-live-feedback"
          placeholder="Enter your domain name"
        ></b-form-input>
        <span class="text-danger" v-if="!validDomain">
          <small>Not a valid domain name</small>
        </span>
        <b-form-invalid-feedback id="input-0-live-feedback">Domain name is required.</b-form-invalid-feedback>
        <div class="kt-login__actions">
          <br>
          <v-btn
            :disabled="!validDomain"
            @click="searchDomain"
            type="button"
            color="white--text v-btn theme--light elevation-2 v-size--large primary"
            class="btn-save-popup"
            text
          >Search</v-btn>
        </div>
      </div>
    </div>
    <domain-search-result
      :response="response"
      :existIncart="existIncart"
      @check-cart="callCheckDomainCart"
    ></domain-search-result>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import DomainService from "@/services/domain/DomainService";
import CartService from "@/services/cart/CartService";
import DomainSearchResult from "./DomainSearchResult";

const domainService = new DomainService();
const cartService = new CartService();

export default {
  name: "domain",
  components: {
    DomainSearchResult
  },
  data() {
    return {
      domain_name: null,
      validDomain: true,
      response: {},
      overlay: false,
      term: 1,
      existIncart: false,
      terms: [
        { text: "1 year", value: "1" },
        { text: "2 year", value: "2" },
        { text: "3 year", value: "3" },
        { text: "4 year", value: "4" },
        { text: "5 year", value: "5" },
        { text: "6 year", value: "6" },
        { text: "7 year", value: "7" },
        { text: "8 year", value: "8" },
        { text: "9 year", value: "9" },
        { text: "9 year", value: "9" },
        { text: "10 year", value: "10" }
      ]
    };
  },
  watch: {
    domain_name: function(val) {
      let regex = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/;
      // let regex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
      if (!regex.test(val)) {
        this.validDomain = false;
      } else {
        this.validDomain = true;
      }
    }
  },
  methods: {
    availabilityCheck() {
      this.overlay = true;
      domainService
        .checkAvailabiltiy(this.domain_name)
        .then(response => {
          this.overlay = false;
          this.response = response.data;
          if (this.response.status === "yes") {
            this.checkDomainInCart();
          }
        })
        .catch(error => {});
    },
    searchDomain() {
      if (this.domain_name == null || this.domain_name == undefined) {
        this.validDomain = false;
        return 0;
      }
      this.availabilityCheck();
    },
    checkDomainInCart() {
      cartService
        .checkDomainInCart(this.domain_name)
        .then(response => {
          if (response.data) {
            this.existIncart = true;
          }
        })
        .catch(error => {});
    },
    callCheckDomainCart() {
      this.checkDomainInCart();
    }
  }
};
</script>
