<template>
  <div class="kt-widget5">
    <perfect-scrollbar style="max-height: 100vh; position: relative;">
      <template v-for="(item, i) in datasrc">
        <div class="kt-widget5__item" :key="`item-${i}`">
          <div class="kt-widget5__content">
            <div class="kt-widget5__pic">
              <img class="kt-widget7__img" src="assets/media/icons/website_icon.png" alt />
            </div>
            <div class="kt-widget5__section">
              <a href="#" class="kt-widget5__title">{{ item.title }}</a>
              <p class="kt-widget5__desc">plan Name</p>
              <div class="kt-widget5__info">
                  <span class="kt-font-info">Visit Website</span><span> Created:</span><span class="kt-font-info">23/08/2017</span>.
              </div>
            </div>
          </div>
          <div class="kt-widget5__content">
            <div class="kt-widget5__stats">
              <span class="kt-widget5__number"  v-text="item.total_page"></span>
              <span class="kt-widget5__sales">pages</span>
            </div>
             
            <div class="kt-widget5__stats">
              <span class="kt-widget5__number" v-text="item.total_blog"></span>
              <span class="kt-widget5__votes">Blogs</span>
            </div>
            
            <!-- <div class="kt-widget5__stats">
              <span class="kt-widget5__number">12</span>
              <span class="kt-widget5__votes">products</span>
            </div> -->
          </div>
        </div>
      </template>
    </perfect-scrollbar>
  </div>
</template>

<script>
export default {
  name: "widget-5",
  components: {},
  props: {
    datasrc: Array
  }
};
</script>
