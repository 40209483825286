import Apiservice from '@/common/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class DomainService {

      checkAvailabiltiy(domainName) {
            let url ='/user/domain/availability';
            return apiService.post(url, {domain_name:domainName});
      }

      paginate(index = null) {
            let url = 'user/domain';
            if (index != null)
                url = url + '?page=' + index
            return apiService.get(url);
        }
}
